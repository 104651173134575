import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";
import { reviews } from "../content/homepage";

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const tablet = useMediaQuery("(max-width:1024px)");

  const settingsMobile = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div
        style={{
          width: "calc(100vw / 50)",
          color: "blue",
          position: "relative",
          placeSelf: "center",
        }}
      >
        <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
        <span
          style={{
            position: "absolute",
            right: "300%",
            cursor: "pointer",
            backgroundColor: i === currentSlide ? "#0c6cf2" : "#042451",
            height: "15px",
            width: "15px",
            borderRadius: "100%",
          }}
        ></span>
      </div>
    ),
  };

  const settingsDesktop = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div
        style={{
          width: "5vw",
          color: "blue",
          position: "relative",
        }}
      >
        <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
        <span
          style={{
            position: "absolute",
            right: "150px",
            cursor: "pointer",
            backgroundColor: i === currentSlide ? "#0c6cf2" : "#042451",
            height: "15px",
            width: "15px",
            borderRadius: "100%",
          }}
        ></span>
      </div>
    ),
  };

  return (
    <div style={{ fontFamily: "Inter" }}>
      <section className="bg-[#001433] px-10 py-20 items-center mt-5">
        {!tablet && (
          <Slider
            {...settingsDesktop}
            afterChange={(e) => setCurrentSlide(e)}
            className="px-20 py-20"
          >
            {reviews.map((review) => (
              <div>
                <figure className="mt-10">
                  <div
                    className="text-center text-xl font-semibold leading-8 text-white lg:text-[48px] lg:leading-[60px] w-full max-w-[1200px]"
                    style={{ margin: "0 auto" }}
                  >
                    <span style={{ fontFamily: "PP Mori" }}>
                      {review.review}
                    </span>
                  </div>
                  <figcaption className="mt-10">
                    <img
                      className="mx-auto h-10 w-10 rounded-full"
                      src={review.image}
                      alt=""
                    />
                    <div
                      className="mt-4 grid items-center justify-center text-base"
                      style={{ justifyItems: "center" }}
                    >
                      <div className="font-semibold text-white">
                        {review.name}
                      </div>
                      <div className="text-[#CEE2FC]">{review.title}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </Slider>
        )}

        {tablet && (
          <Slider
            {...settingsMobile}
            afterChange={(e) => setCurrentSlide(e)}
            className="px-5 py-20"
          >
            {reviews.map((review) => (
              <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <figure className="mt-10">
                  <div
                    className="text-center text-xl font-semibold leading-8 text-white lg:text-[48px] lg:leading-[60px] w-full max-w-[1200px]"
                    style={{ margin: "0 auto" }}
                  >
                    <span style={{ fontFamily: "PP Mori" }}>
                      {review.review}
                    </span>
                  </div>
                  <figcaption className="mt-10">
                    <img
                      className="mx-auto h-10 w-10 rounded-full"
                      src={review.image}
                      alt=""
                    />
                    <div
                      className="mt-4 grid items-center justify-center text-base"
                      style={{ justifyItems: "center" }}
                    >
                      <div className="font-semibold text-white">
                        {review.name}
                      </div>
                      <div className="text-[#CEE2FC]">{review.title}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </Slider>
        )}
      </section>
    </div>
  );
};

export default Testimonials;
