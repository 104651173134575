import PrivacyText from "../components/PrivacyText";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <div>
      <Header style={"dark"} />
      <PrivacyText />
      <Footer />
    </div>
  );
};

export default Privacy;
