import { siteSettings } from "../static";

export default function TermsText() {
  return (
    <div
      className="bg-white px-6 py-32 lg:px-8"
      style={{ fontFamily: "Inter" }}
    >
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-[#0c6cf2]">
          Terms
        </p>
        <p className="mt-2 text-xl leading-8" style={{ fontFamily: "PP Mori" }}>
          Welcome to {siteSettings.siteName}'s Terms of Service. By using our
          services, you agree to comply with the following terms and conditions:
        </p>

        {/* first off */}
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            First Off
          </h2>
          <p className="mt-6">
            1. Unrestricted Usage: {siteSettings.siteName} imposes no
            restrictions on the usage of our proxies. Feel free to harness the
            full potential of our services for your online activities.
            <br />
            <br />
            2. Payment Options: {siteSettings.siteName} offers multiple payment
            methods, including cryptocurrencies, PayPal, and credit/debit cards,
            to ensure seamless and flexible transactions.
            <br />
            <br />
            3. Account Security: Users are responsible for maintaining the
            confidentiality of their accounts and passwords. Notify{" "}
            {siteSettings.siteName} immediately of any unauthorized account access.
            <br />
            <br />
            4. Billing and Refunds: Accurate billing information is essential.
            In the event of non-payment or fraudulent activity,{" "}
            {siteSettings.siteName} reserves the right to suspend services. No refunds
            <br />
            <br />
            5. Service Availability: While {siteSettings.siteName} strives for
            continuous service availability, occasional downtime or technical
            issues may arise. We are not liable for any resulting damages.
            <br />
            <br />
            6. Privacy Commitment: {siteSettings.siteName} upholds strict
            privacy standards. Our Privacy Policy outlines data collection, use,
            and protection practices. By using our services, you consent to
            these practices.
            <br />
            <br />
            7. Liability Limitation: {siteSettings.siteName} disclaims liability
            for damages or losses arising from service use. Users agree to
            indemnify and hold {siteSettings.siteName} harmless from legal
            actions due to their activities.
            <br />
            <br />
            8. Terms Modification: {siteSettings.siteName} reserves the right to
            modify these terms as needed. Users will be notified of significant
            changes. Continued service use signifies acceptance of updated
            terms.
            <br />
            <br />
            By utilizing {siteSettings.siteName}'s services, you acknowledge
            your agreement with these terms. Your satisfaction and secure online
            experience are paramount to us. Thank you for choosing{" "}
            {siteSettings.siteName}.
          </p>
        </div>

        {/* Disclaimer Of Liability */}
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            Disclaimer Of Liability
          </h2>
          <p className="mt-6">
            {siteSettings.siteName}'s services are provided with the
            understanding that we are not liable for any consequences arising
            from their use. By utilizing our services, you agree to the
            following terms:
            <br />
            <br />
            1. User Responsibility: Users of {siteSettings.siteName}'s proxies
            are solely responsible for their actions and usage. We are not
            accountable for any damages, legal disputes, or losses resulting
            from user activities.
            <br />
            <br />
            2. No Legal Obligation: {siteSettings.siteName} disclaims any legal
            obligation for actions taken by our clients while using our proxies.
            In the event that damages, lawsuits, disputes, or liabilities arise
            from user activities, clients accept full responsibility and are
            liable for any associated costs, while {siteSettings.siteName}{" "}
            maintains its position of non-involvement.
            <br />
            <br />
            3. Indemnification: Indemnification: Users agree to indemnify,
            defend, and hold {siteSettings.siteName} harmless from any legal
            actions, claims, or damages resulting from their use of our
            services. Should customer activities lead to damages or adverse
            events, clients bear the financial responsibility for such outcomes,
            absolving {siteSettings.siteName}
            from any obligation to cover costs or assume liability. In cases of
            unforeseen or significant events, {siteSettings.siteName} maintains
            its stance of non-liability and non-responsibility.
            <br />
            <br />
            4. Changes to Terms: These terms may change without notice.
            Continued use of our services after modifications constitutes
            acceptance of the updated terms.
            <br />
            <br />
            5. Compliance with Laws: Users are required to adhere to all local,
            national, and international laws while using {siteSettings.siteName}
            's proxies. Any unlawful activities are the sole responsibility of
            the user.
            <br />
            <br />
            6. Service Modifications: {siteSettings.siteName} reserves the right
            to modify, suspend, or terminate services if user activities violate
            our terms of service or pose a risk to our infrastructure.
            <br />
            <br />
            7. INo Warranty: {siteSettings.siteName} provides no warranty,
            express or implied, for the performance, reliability, or suitability
            of our services for specific purposes.
            <br />
            <br />
            {siteSettings.siteName} prioritizes providing secure, high-quality
            proxies. By using our services, you acknowledge your understanding
            and agreement with these terms. Thank you for choosing{" "}
            {siteSettings.siteName}.
          </p>
        </div>
      </div>
    </div>
  );
}
