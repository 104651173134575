const Discord = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33329 24.0001V23.7334C5.33329 21.4932 5.33329 20.3731 5.76927 19.5175C6.15276 18.7648 6.76468 18.1529 7.51733 17.7694C8.37298 17.3334 9.49308 17.3334 11.7333 17.3334H20.2666C22.5068 17.3334 23.6269 17.3334 24.4826 17.7694C25.2352 18.1529 25.8472 18.7648 26.2307 19.5175C26.6666 20.3731 26.6666 21.4932 26.6666 23.7334V24.0001M5.33329 24.0001C3.86053 24.0001 2.66663 25.194 2.66663 26.6667C2.66663 28.1395 3.86053 29.3334 5.33329 29.3334C6.80605 29.3334 7.99996 28.1395 7.99996 26.6667C7.99996 25.194 6.80605 24.0001 5.33329 24.0001ZM26.6666 24.0001C25.1939 24.0001 24 25.194 24 26.6667C24 28.1395 25.1939 29.3334 26.6666 29.3334C28.1394 29.3334 29.3333 28.1395 29.3333 26.6667C29.3333 25.194 28.1394 24.0001 26.6666 24.0001ZM16 24.0001C14.5272 24.0001 13.3333 25.194 13.3333 26.6667C13.3333 28.1395 14.5272 29.3334 16 29.3334C17.4727 29.3334 18.6666 28.1395 18.6666 26.6667C18.6666 25.194 17.4727 24.0001 16 24.0001ZM16 24.0001V10.6667M7.99996 10.6667H24C25.2425 10.6667 25.8637 10.6667 26.3538 10.4638C27.0072 10.1931 27.5263 9.67398 27.797 9.02057C28 8.53051 28 7.90926 28 6.66675C28 5.42424 28 4.80298 27.797 4.31293C27.5263 3.65952 27.0072 3.14039 26.3538 2.86974C25.8637 2.66675 25.2425 2.66675 24 2.66675H7.99996C6.75745 2.66675 6.13619 2.66675 5.64614 2.86974C4.99273 3.14039 4.4736 3.65952 4.20295 4.31293C3.99996 4.80298 3.99996 5.42424 3.99996 6.66675C3.99996 7.90926 3.99996 8.53051 4.20295 9.02057C4.4736 9.67398 4.99273 10.1931 5.64614 10.4638C6.13619 10.6667 6.75745 10.6667 7.99996 10.6667Z"
        stroke="#0C6CF2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Discord;
