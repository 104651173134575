import About from "../components/About";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";

const Main = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Hero />
      <div id="about" className="pt-24">
        <About />
      </div>
      <div id="services" className="pt-24">
        <Services />
      </div>
      <div id="pricing" className="pt-24">
        <Pricing />
      </div>
      <div id="testimonials" className="pt-24">
        <Testimonials />
      </div>
      <Footer />
    </div>
  );
};

export default Main;
