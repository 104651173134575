import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { headerContent } from "../content/partials";
import { siteSettings } from "../static";
import { useNavigate } from "react-router-dom";

const Header = ({ style }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  function handleMobileLink(href) {
    const anchor = document.createElement("a");
    anchor.href = href;
    anchor.click();
    setMobileMenuOpen(false);
  }

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">{siteSettings.siteName}</span>
            <img className="h-8 w-auto" src="/logo.png" alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className={
              style === "dark"
                ? "-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-[#001433]"
                : "-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            }
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {headerContent.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold leading-6 text-white"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-[12px]">
          <a
            href={siteSettings.registerLink}
            className="rounded-[8px] bg-[#0c6cf2] hover:bg-[#0A5ACA] px-3.5 py-2.5 text-[sm] font-semibold text-[#fff]  flex space-x-[8px] items-center"
          >
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontStyle: "normal",
              }}
            >
              Get started
            </p>
          </a>

          <a
            href={siteSettings.loginLink}
            className="rounded-[8px] bg-[#fff] px-3.5 py-2.5 text-sm font-semibold text-[#021630]  flex space-x-[8px] items-center"
          >
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontStyle: "normal",
              }}
            >
              Log in
            </p>
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">{siteSettings.siteName}</span>
              <img className="h-8 w-auto" src="/logo.png" alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {headerContent.map((item) => (
                  <span
                    key={item.name}
                    onClick={() => handleMobileLink(item.href)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                  >
                    {item.name}
                  </span>
                ))}
              </div>
              <div className="py-6">
                <a
                  href={siteSettings.loginLink}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
