const Performance = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 4.00008H10.4C8.15979 4.00008 7.03969 4.00008 6.18404 4.43606C5.43139 4.81955 4.81947 5.43147 4.43597 6.18412C4 7.03977 4 8.15987 4 10.4001V21.6001C4 23.8403 4 24.9604 4.43597 25.816C4.81947 26.5687 5.43139 27.1806 6.18404 27.5641C7.03969 28.0001 8.15979 28.0001 10.4 28.0001H21.6C23.8402 28.0001 24.9603 28.0001 25.816 27.5641C26.5686 27.1806 27.1805 26.5687 27.564 25.816C28 24.9604 28 23.8403 28 21.6001V17.3334M16 10.6667H21.3333V16.0001M20.6667 4.66675V2.66675M25.9191 6.08096L27.3333 4.66675M27.347 11.3334H29.347M4 17.7962C4.86925 17.9304 5.75983 18.0001 6.66667 18.0001C12.5151 18.0001 17.6871 15.1035 20.8262 10.6667"
        stroke="#0C6CF2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Performance;
