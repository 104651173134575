export const siteSettings = {
  siteName: "ProxyLogic",
  siteLink: "https://proxylogic.org",
  dashboardLink: "https://dash.proxylogic.org",
  registerLink: "https://dash.proxylogic.org/register",
  loginLink: "https://dash.proxylogic.org/login",
  documentationLink: "https://docs.proxylogic.org",
  supportEmail: "contact@proxylogic.org",
  discordLink: "https://discord.gg/y5R2HCEpwH",
};
