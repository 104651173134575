import Unlimited from "../assets/illustrations/Unlimited";
import Satisfaction from "../assets/illustrations/Satisfaction";
import Support from "../assets/illustrations/Support";
import {
  servicesFeatures,
  servicesTag,
  servicesTitle,
} from "../content/homepage";

export default function Services() {
  return (
    <div
      className="overflow-hidden bg-white py-12"
      style={{ fontFamily: "Inter" }}
    >
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-[#0C6CF2]">
            {servicesTitle}
          </h2>
          <p
            className="mt-2 text-3xl px-4 lg:px-0 font-bold text-gray-900 lg:text-[36px] lg:leading-[44px]"
            style={{ fontFamily: "PP Mori" }}
          >
            {servicesTag}
          </p>
        </div>

        {servicesFeatures.map((feature, index) => (
          <div
            className="lg:gap-x-[200px] grid lg:flex items-center mt-24 lg:mt-0"
            key={feature.name}
            style={{ flexDirection: index === 1 && "row-reverse" }}
          >
            <div className="relative px-4 lg:pl-9 ">
              <span
                className="inline font-semibold text-gray-900 "
                style={{ fontFamily: "PP Mori", fontSize: "20px" }}
              >
                <span
                  className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                  aria-hidden="true"
                />
                {feature.name}
              </span>
              <br />
              <div
                className="lg:max-w-[400px] text-lg text-[#001433]"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "30px",
                }}
              >
                {feature.description}
              </div>
            </div>
            <div
              style={{
                display: "grid",
                placeContent: "center",
              }}
              className=" lg:px-[80px]"
            >
              {/* Icons */}
              {feature.name === "Unlimited IP Addresses" && <Unlimited />}
              {feature.name === "Satisfaction Guaranteed" && <Satisfaction />}
              {feature.name === "24/7 Expert Support" && <Support />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
