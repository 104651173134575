import Dashboard from "../assets/icons/Dashboard";
import Discord from "../assets/icons/Discord";
import Performance from "../assets/icons/Performance";
import { aboutFeatures, aboutTag, aboutTitle } from "../content/homepage";

export default function About() {
  return (
    <div className="bg-white" style={{ fontFamily: "Inter" }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-[#0C6CF2]">
            {aboutTitle}
          </h2>
          <p
            className="mt-2 text-3xl px-4 lg:px-0 font-bold text-gray-900 lg:text-[36px] lg:leading-[44px]"
            style={{ fontFamily: "PP Mori" }}
          >
            {aboutTag}
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 md:max-w-none md:grid-cols-2 lg:grid-cols-3 mx-auto max-w-7xl lg:px-8">
            {aboutFeatures.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt
                  className="text-base font-semibold leading-7 text-gray-900"
                  style={{ fontFamily: "PP Mori" }}
                >
                  <div className="my-4">
                    {/* icons */}
                    {feature.name === "Streamlined Dashboard" && <Dashboard />}
                    {feature.name === "Integrated Discord Bot" && <Discord />}
                    {feature.name === "Unrivaled Proxy Performance" && (
                      <Performance />
                    )}
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
