import { siteSettings } from "../static";

// hero
export const heroContent = {
  title: "Amplify your online access with our proxy solutions",
  description:
    "Welcome to ProxyLogic, your ultimate destination for top-tier residential proxies at unbeatable prices. Experience unmatched speed and quality as you navigate the online realm seamlessly.",
  buttons: [
    {
      title: "Get started",
      href: siteSettings.dashboardLink,
    },
    {
      title: "Check out our prices",
      href: "#pricing",
    },
  ],
};

// about us
export const aboutTitle = "About us";
export const aboutTag =
  "Experience unmatched speed and affordability for seamless online navigation.";
export const aboutFeatures = [
  {
    name: "Streamlined Dashboard",
    description:
      "Enjoy a user-friendly dashboard designed for easy navigation, where you can access essential information and settings to enhance your proxy experience.",
  },
  {
    name: "Integrated Discord Bot",
    description:
      "Seamlessly manage your proxies using our dedicated Discord bot, providing you with an intuitive and efficient way to control and monitor your proxy usage.",
  },
  {
    name: "Unrivaled Proxy Performance",
    description:
      "Experience exceptional speed and reliability with our premium residential proxies, ensuring smooth online activities and uninterrupted browsing.",
  },
];

// services
export const servicesTitle = "Why Choose Our Services";
export const servicesTag =
  "Unlock the full potential of your online experience with ProxyLogic's top-rated residential proxies.";
export const servicesFeatures = [
  {
    name: "24/7 Expert Support",
    description:
      "ProxyLogic is committed to your success, offering 24/7 support for inquiries, technical issues, and customization needs.",
  },
  {
    name: "Satisfaction Guaranteed",
    description:
      "At ProxyLogic, we stand behind our proxy services with unwavering confidence. We will help if you're not completely satisfied with our team.",
  },
  {
    name: "Unlimited IP Addresses",
    description:
      "Whether for secure online navigation or tailored business solutions, we offer an array of unique IP addresses at your disposal.",
  },
];

// pricing
export const pricingTitle = "Buy Our Plans & Packages";
export const pricingTag =
  " Your hub for premium proxies. Explore, manage and unleash speed and reliability as you browse";
export const pricingTiers = [
  {
    name: "Mobile",
    priceMonthly: 9,
    priceDisplay: "$2/Day Purchase",
  },
  {
    name: "Premium Residential",
    priceMonthly: 29,
    priceDisplay: "$2.9/GB Purchase",
  },
  {
    name: "Basic Residential",
    priceMonthly: 59,
    priceDisplay: "$2.5/GB Purchase",
  },
  {
    name: "IPV6 Proxy",
    priceMonthly: 59,
    priceDisplay: "$2/Day Purchase",
  },
];

export const pricingSections = [
  {
    name: "Features",
    features: [
      {
        name: "Worldwide IPs",
        tiers: {
          Mobile: false,
          "Premium Residential": true,
          "Basic Residential": true,
          "IPV6 Proxy": true,
        },
      },
      {
        name: "Unlimited Concurrent Connections",
        tiers: {
          Mobile: false,
          "Premium Residential": true,
          "Basic Residential": true,
          "IPV6 Proxy": true,
        },
      },
      {
        name: "Connection",
        tiers: {
          Mobile: "HTTP/HTTPS/SOCKS5",
          "Premium Residential": "HTTP/HTTPS/SOCKS5",
          "Basic Residential": "HTTP/HTTPS",
          "IPV6 Proxy": "SOCKS5",
        },
      },
      {
        name: "Types",
        tiers: {
          Mobile: "4G/WIFI/CARRIER",
          "Premium Residential": "Residential",
          "Basic Residential": "Residential",
          "IPV6 Proxy": "IPV6",
        },
      },
      {
        name: "Rotation",
        tiers: {
          Mobile: "6H/30M/Auto",
          "Premium Residential": "Rotation/Sticky",
          "Basic Residential": "Rotation/Sticky",
          "IPV6 Proxy": "Auto",
        },
      },
      {
        name: "Authentication",
        tiers: {
          Mobile: "IP Whitelist",
          "Premium Residential": "Username:Password",
          "Basic Residential": "Username:Password",
          "IPV6 Proxy": "Username:Password",
        },
      },
      {
        name: "Instant Setup",
        tiers: {
          Mobile: true,
          "Premium Residential": true,
          "Basic Residential": true,
          "IPV6 Proxy": true,
        },
      },
    ],
  },
];

// testimonials
export const reviews = [
  {
    name: "rhsy",
    image:
      "https://user-images.trustpilot.com/62f64d015f9959001469c9f1/73x73.png",
    title: "Insane support",
    review:
      "its not my first time purchasing from them, but recently i had an issue with the proxies by my side, joined to their discord server and created ticket asking for an help, after seconds they were already answering me and trying to help me out, and they did. they are kind against their customers and helpful. recommended.",
  },
  {
    name: "Andrzej Wojtczyk",
    image:
      "https://user-images.trustpilot.com/655932c4192b8f0012aed291/73x73.png",
    title: "The best service I ever used",
    review: "The best service I ever used, to be honest. From the beginning it was easy to understand how to proceed compared with other companies. Very helpful staff, good prices.",
  },
  {
    name: "YOP",
    image:
      "https://user-images.trustpilot.com/65245935258e7900122d5aa7/73x73.png",
    title: "Top Service",
    review:
      "I couldn't add the top-up money to the site. They instantly helped throw the process of it. Everything is working. I recommand this service!",
  },
  {
    name: "soxie",
    image:
      "https://user-images.trustpilot.com/6304aa40cc230c001374b256/73x73.png",
    title: "Get on discord and use the support there, its great!",
    review:
      "They went to every single step and helped me with everything, its really great!",
  },
  {
    name: "Gekido Doe",
    image:
      "https://user-images.trustpilot.com/655fbaebb0a3120012cd748b/73x73.png",
    title: "Great support, cooperative, helpful",
    review:
      "I like the service, best customer service, keep it up!",
  },
];
