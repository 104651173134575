export const faqs = [
  {
    id: 1,
    question: "What are Residential Proxies?",
    answer:
      "Residential proxies are IP addresses provided by Internet Service Providers (ISPs) to homeowners. They offer enhanced privacy and security for online activities by masking your original IP, allowing you to access websites anonymously.",
  },
  {
    id: 2,
    question: "How Does ProxyLogic Ensure Speed And Reliability?",
    answer:
      "ProxyLogic's premium residential proxies are optimized for exceptional speed and reliability. Our dedicated infrastructure, along with strategically located servers, guarantees minimal latency and seamless browsing.",
  },
  {
    id: 3,
    question: "Can I Customize My Data Package?",
    answer:
      "Absolutely! ProxyLogic offers a range of data packages to suit your browsing needs. Choose from 1GB, 5GB, and 10GB packs. For power users requiring more, take advantage of our exclusive 2.5€/GB packs at an unbeatable rate.",
  },

  {
    id: 4,
    question: "How Do I Get Started With ProxyLogic?",
    answer:
      "Getting started is simple. Explore our shop, select the desired data package, and use our secure payment process to make your purchase. Upon confirmation, you'll receive access to your proxies and Discord bot commands, to redeem your key, you can make a ticket on the discord server and use the !redeem command or use our dashboard.",
  },
  {
    id: 5,
    question: "Are There Usage Restrictions On Proxies?",
    answer:
      "ProxyLogic believes in unrestricted potential. There are no usage restrictions, allowing you the freedom to leverage our premium residential proxies for a wide range of online activities.",
  },
  {
    id: 6,
    question: "How Can I Reach ProxyLogic's Customer Support?",
    answer: ``,
  },
];
