import Header from "../components/Header";
import Footer from "../components/Footer";
import TermsText from "../components/TermsText";

const Terms = () => {
  return (
    <div>
      <Header style={"dark"} />
      <TermsText />
      <Footer />
    </div>
  );
};

export default Terms;
