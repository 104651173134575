import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import { siteSettings } from "../static";
import {
  pricingSections,
  pricingTag,
  pricingTiers,
  pricingTitle,
} from "../content/homepage";

export default function Pricing() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base font-semibold leading-7 text-[#0C6CF2]">
          {pricingTitle}
        </h2>
        <p
          className="mt-2 text-3xl px-4 lg:px-0 font-bold text-gray-900 lg:text-[36px] lg:leading-[44px]"
          style={{ fontFamily: "PP Mori" }}
        >
          {pricingTag}
        </p>
      </div>

      {/* Comparison table */}
      <div className="mx-auto max-w-2xl bg-white py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {/* xs to lg */}
        <div className="space-y-24 lg:hidden">
          {pricingTiers.map((tier) => (
            <section key={tier.name}>
              <div className="mb-8 px-6 sm:px-4">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  {tier.name}
                </h2>
                {/* <p className="mt-4">
                  <span className="text-lg font-semibold tracking-tight text-gray-900">
                    {tier.priceDisplay}
                  </span>
                </p> */}
              </div>

              {pricingSections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="border-t border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium text-gray-900 sm:px-4">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr
                        key={feature.name}
                        className="border-t border-gray-200"
                      >
                        <th
                          className="px-6 py-5 text-left text-sm font-normal text-gray-500 sm:px-4"
                          scope="row"
                        >
                          {feature.name}
                        </th>
                        <td className="py-5 pr-6 sm:pr-4">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <span className="block text-right text-sm text-gray-700">
                              {feature.tiers[tier.name]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon
                                  className="ml-auto h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="ml-auto h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div className="border-t border-gray-200 px-6 pt-5 sm:px-4">
                <a
                  href={siteSettings.dashboardLink}
                  className="block w-full rounded-md border border-transparent bg-[#0c6cf2] hover:bg-[#0A5ACA] py-2 text-center text-sm font-semibold text-white shadow"
                >
                  {tier.priceDisplay}
                </a>
              </div>
            </section>
          ))}
        </div>

        {/* table-fixed */}

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="h-px w-full ">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th
                  className="pb-4 pl-6 pr-6 text-left text-sm font-medium text-gray-900"
                  scope="col"
                >
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {pricingTiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/4 px-6 pb-4 text-left text-lg font-medium leading-6 text-gray-900"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
              {/* <tr>
                <th
                  className="py-8 pl-6 pr-6 text-left align-top text-sm font-medium text-gray-900"
                  scope="row"
                >
                  Pricing
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="h-full px-6 py-8 align-top">
                    <div className="flex h-full flex-col justify-between">
                      <div>
                        <p>
                          <span className="text-lg font-semibold tracking-tight text-gray-900">
                            {tier.priceDisplay}
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                ))}
              </tr> */}
              {pricingSections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        className="py-5 pl-6 pr-6 text-left text-sm font-normal text-gray-500"
                        scope="row"
                      >
                        {feature.name}
                      </th>
                      {pricingTiers.map((tier) => (
                        <td key={tier.name} className="px-6 py-5">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <span className="block text-sm text-gray-700">
                              {feature.tiers[tier.name]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon
                                  className="h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true
                                  ? "Included"
                                  : "Not included"}{" "}
                                in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {pricingTiers.map((tier) => (
                  <td key={tier.name} className="px-6 pt-5">
                    <a
                      href={siteSettings.dashboardLink}
                      className="block w-full rounded-md border border-transparent bg-[#0c6cf2] hover:bg-[#0A5ACA] py-2 text-center text-sm font-semibold text-white shadow "
                    >
                      {tier.priceDisplay}
                    </a>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
