import { faqs } from "../content/faqpage";
import { siteSettings } from "../static";

export default function Questions() {
  return (
    <div className="bg-[#001433] pt-24" style={{ fontFamily: "Inter" }}>
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <h2 className="text-base font-semibold leading-7 text-white">
          Frequently asked questions
        </h2>

        <p
          className="mt-2 text-lg text-[#CEE2FC] lg:text-[20px] lg:leading-[44px]"
          style={{ fontFamily: "PP Mori" }}
        >
          Have a different question and can’t find the answer you’re looking
          for? Reach out to our support team by{" "}
          <a
            href={`mailto:${siteSettings.supportEmail}`}
            className="font-semibold text-[#0c6cf2] hover:text-[#0A5ACA]"
          >
            sending us an email
          </a>{" "}
          and we’ll get back to you as soon as we can.
        </p>

        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt
                  className="text-base font-semibold leading-7 text-white"
                  style={{ fontFamily: "PP Mori" }}
                >
                  {faq.question}
                </dt>
                {faq.id !== 6 && (
                  <dd className="mt-2 text-base leading-7 text-[#CEE2FC]">
                    {faq.answer}
                  </dd>
                )}
                {faq.id === 6 && (
                  <dd className="mt-2 text-base leading-7 text-[#CEE2FC]">
                    For assistance, questions, or clarifications, you can reach
                    out to our customer support team via{" "}
                    <a
                      href={`mailto:${siteSettings.supportEmail}`}
                      className="text-[#0c6cf2] hover:text-[#0A5ACA]"
                    >
                      {siteSettings.supportEmail}{" "}
                    </a>{" "}
                    or through our Discord server at{" "}
                    <a
                      href={siteSettings.discordLink}
                      className="text-[#0c6cf2] hover:text-[#0A5ACA]"
                    >
                      {siteSettings.discordLink}{" "}
                    </a>{" "}
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
