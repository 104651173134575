import { footerContent } from "../content/partials";
import { siteSettings } from "../static";

export default function Footer() {
  return (
    <footer
      className="bg-[#001433] items-center grid text-center"
      style={{ fontFamily: "Inter" }}
    >
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {footerContent.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-[#CEE2FC] hover:text-[#85B5F8]"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="text-sm leading-6 text-[#CEE2FC] py-8">
          {footerContent.description}
        </p>
        <p className="text-center text-xs leading-5 text-[#CEE2FC]">
          &copy; {new Date().getFullYear()} {siteSettings.siteName}. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}
