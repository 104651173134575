import { heroContent } from "../content/homepage";
import Header from "./Header";

export default function Hero() {
  return (
    <div className="bg-[#021630]" style={{ fontFamily: "Inter" }}>
      <Header />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto lg:max-w-5xl py-32 sm:py-48 lg:py-56 max-w-xl">
          <div
            className="text-center"
            style={{ display: "grid", justifyItems: "center" }}
          >
            <h1
              className="text-3xl font-bold text-white lg:text-[72px] lg:leading-[92px] hero_text"
              style={{
                fontFamily: "PP Mori",
                fontWeight: 600,
              }}
            >
              {heroContent.title.split(" ").map((item) => (
                <span>
                  {""} {item}
                </span>
              ))}
            </h1>
            <p className="mt-6 text-l lg:text-lg leading-8 text-[#CEE2FC] max-w-[870px] hero_support">
              {heroContent.description}
            </p>
            <div className="mt-10 grid lg:flex w-full  items-center justify-center gap-6 hero_buttons">
              <a
                href={heroContent.buttons[0].href}
                className="rounded-[8px] bg-[#0C6CF2] hover:bg-[#0A5ACA] px-3.5 py-2.5 text-[16px] font-semibold text-white flex space-x-[8px]"
                style={{
                  justifyItems: "space-between",
                  maxWidth: "max-content",
                }}
              >
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontStyle: "normal",
                  }}
                >
                  {heroContent.buttons[0].title}
                </p>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 18L15 12L9 6"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <a
                href={heroContent.buttons[1].href}
                className="text-sm font-semibold leading-6 text-white text-[16px]"
              >
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontStyle: "normal",
                  }}
                >
                  {heroContent.buttons[1].title}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
