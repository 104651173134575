import { siteSettings } from "../static";

// header
export const headerContent = [
  { name: "About", href: `${siteSettings.siteLink}/#about` },
  { name: "Services", href: `${siteSettings.siteLink}/#services` },
  { name: "Pricing", href: `${siteSettings.siteLink}/#pricing` },
];

// footer
export const footerContent = {
  description:
    " ProxyLogic is your trusted partner for premium residential proxies, delivering unparalleled speed, reliability, and affordability.",
  main: [
    { name: "Dashboard", href: siteSettings.dashboardLink },
    { name: "Home", href: "/" },
    { name: "Terms", href: "/terms" },
    { name: "FAQ", href: "/faq" },
    { name: "Privacy Policy", href: "/privacy" },
    { name: "Documentation", href: siteSettings.documentationLink },
  ],
};
